import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button } from 'react-bootstrap';
import { AppContext } from "../context/ContextProvider";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Lightbox from 'react-image-lightbox';

moment.locale('th')

const BookingAvailableModal = ({ show, onHide = () => { } }) => {
  const navigate = useNavigate();
  const { currentHotel,
    handleSetBookStartDate,
    handleSetBookEndDate,
    handleSetBookingData,
    roomSelected,
  } = useContext(AppContext);
  const hotel_id = currentHotel?.id || null

  const [rooms, setRooms] = useState([])
  const [bookings, setBookings] = useState([])

  const [mainCurrentStartDate, setMainCurrentStartDate] = useState(new Date());
  const [blockdays, setBlockdays] = useState([]);
  const [availableDataForBooking, setAvailableDataForBooking] = useState(null);

  const [customTentConfigs, setCustomTentConfigs] = useState([]);
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)

  const fetchRooms = (_hotelId) => {
    api.request(`rooms/all`, (res) => {
      setRooms(res || [])
    }, {
      enabled: true,
      hotel_id: _hotelId
    })
  }

  const fetchฺBookingFromDate = (currentDate) => {
    if (!currentDate || !hotel_id) {
      setBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchฺBlockdayFromDate = (currentDate) => {
    if (!currentDate || !hotel_id) {
      setBlockdays([])
      return
    }

    api.request('bookings/all-check-room-blockday', (data) => {
      if (data && data.length) {
        setBlockdays(data.map((item) => {
          return {
            date: item.date,
            room_id: item.room_id,
            room_name: item.room?.name_th,
            note: item.note,
          }
        }) || [])
      } else {
        setBlockdays([])
      }
    }, {
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchCustomTentConfig = (currentDate) => {
    if (!currentHotel?.allow_walkin || !currentDate || !hotel_id) {
      setCustomTentConfigs([])
      return
    }

    api.request(`custom-tent/allcriteria`, (data) => {
      setCustomTentConfigs(data || [])
    }, {
      enabled: true,
      hotel_id: hotel_id,
      start_date: moment(currentDate).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(currentDate).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  useEffect(() => {
    if (!show || !hotel_id) {
      return;
    }
    fetchRooms(hotel_id);
    fetchฺBookingFromDate(mainCurrentStartDate)
    fetchฺBlockdayFromDate(mainCurrentStartDate)
    fetchCustomTentConfig(mainCurrentStartDate)
  }, [show, hotel_id]);

  useEffect(() => {
    if (!hotel_id) {
      return
    }

    if (!!mainCurrentStartDate) {
      fetchฺBookingFromDate(mainCurrentStartDate)
      fetchฺBlockdayFromDate(mainCurrentStartDate)
      fetchCustomTentConfig(mainCurrentStartDate)
    }
  }, [mainCurrentStartDate])

  const checkCloseAll = (date) => {
    const hasClose = bookings.filter((item) => {
      return item.is_close_zone && item.close_zone_type === 'all' && date >= item.start_date && date <= item.end_date
    })

    return hasClose.length > 0
  }

  const checkBooking = (date) => {
    const hasBooking = bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date
    }) || []

    const carBooking = hasBooking.filter((item) => {
      return !!item.room_id && !item.is_walkin
    })

    const walkinBooking = hasBooking.filter((item) => { return item.is_walkin })
    const walkinPerson = walkinBooking.map((b) => { return Number(b.guest_qty || 0) + Number(b.guest_child_qty || 0) }).reduce((a, b) => a + b, 0)

    return {
      all: hasBooking.length || 0,
      car: carBooking.length || 0,
      carNames: carBooking.length > 0 ? ((carBooking.map((b) => { return b.room?.name_th }) || []).join(', ')) : '',
      walkin: walkinBooking.length || 0,
      walkin_person: walkinPerson || 0
    }
  }

  const getBlockdayInfo = (date) => {
    return blockdays.filter((item) => {
      return date === item.date
    }) || []
  }

  const getBookingInfoWithRoom = (date) => {
    return bookings.filter((item) => {
      return !item.is_close_zone && date >= item.start_date && date <= item.end_date && !!item.room_id && !item.is_walkin
    }) || []
  }

  const checkWakingGuestQty = (date, _bookingPerson = 0) => {
    if (!currentHotel?.allow_walkin) {
      return 0
    }

    const isCloseWalking = customTentConfigs.find((x) => moment(x.date).isSame(moment(date), 'day') && x.qty === 0) || null
    if (isCloseWalking) {
      return 0
    }

    let limit = Number(currentHotel?.walkin_limit || 0)
    const _customLimit = customTentConfigs.find((x) => moment(x.date).isSame(moment(date), 'day') && x.qty > 1) || null

    if (_customLimit) {
      limit = Number(_customLimit.qty)
    }

    if (_bookingPerson >= limit) {
      return 0
    }

    return _bookingPerson > 0 ? (limit - Number(_bookingPerson || 0)) : limit
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date', 'px-1'];
    const isPartDate = moment(date).isBefore(moment(), 'day')
    const dateStr = moment(date).format('YYYY-MM-DD')

    if (moment(date).isoWeekday() === 5 || moment(date).isoWeekday() === 6) {
      classes.push('custom-weekend');
    }

    if (isPartDate) {
      classes.push('custom-part-date');
    }

    return classes.join(' ');
  }

  const onClickDay = (date) => {
    const dateStr = moment(date).format('YYYY-MM-DD')
    const isCloseAll = checkCloseAll(dateStr)

    if (isCloseAll) {
      return
    }

    const checkBlockdayData = getBlockdayInfo(dateStr)
    const checkBookingData = checkBooking(dateStr)

    const roomDiff = (rooms.length || 0) - (checkBookingData.car || 0) - (checkBlockdayData.length || 0)
    const availableRoom = roomDiff > 0 ? roomDiff : 0
    const availableWalkinGuest = checkWakingGuestQty(date, (checkBookingData.walkin_person || 0))
    // const availableWalkinGuest = (currentHotel?.walkin_limit || 0) - (checkBookingData.walkin_person || 0)

    if (availableRoom <= 0 && availableWalkinGuest <= 0) {
      return
    }

    const roomsNotAvailable = getBookingInfoWithRoom(dateStr)
    const availableRoomData = rooms.filter((room) => {
      return !checkBlockdayData.find((b) => { return b.room_id === room.id }) && !roomsNotAvailable.find((b) => { return b.room_id === room.id })
    })

    const availableData = {
      date: dateStr,
      availableRoomQty: availableRoom,
      availableWalkinGuestQty: availableWalkinGuest,
      availableRoomData: availableRoomData || [],
    }

    setAvailableDataForBooking(availableData)
  }

  return (
    <>
      <Modal show={show} onHide={() => onHide(true)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="p-2">
          <Modal.Title><h5 className="mb-0">กดวันที่ต้องการเข้าพัก</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="booking-form-input">
            <div className="mt-2">
              <div className={`mt-2 mb-4`} style={{ maxWidth: 500 }}>
                <div className="mb-2 d-flex title-btn align-items-center">
                  <span onClick={() => setMainCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                </div>
                <Calendar
                  showDoubleView={false}
                  minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={mainCurrentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    let priceStr = ''
                    const dateStr = moment(date).format('YYYY-MM-DD')
                    const isToday = moment(date).isSame(moment(), 'day')
                    const isPartDate = moment(date).isBefore(moment(), 'day')

                    const checkBlockdayData = getBlockdayInfo(dateStr)
                    const isCloseAll = checkCloseAll(dateStr)

                    const checkBookingData = checkBooking(dateStr)

                    const roomDiff = (rooms.length || 0) - (checkBookingData.car || 0) - (checkBlockdayData.length || 0)
                    const availableRoom = roomDiff > 0 ? roomDiff : 0
                    const availableWalkinGuest = checkWakingGuestQty(date, (checkBookingData.walkin_person || 0))
                    // const availableWalkinGuest = (currentHotel?.walkin_limit || 0) - (checkBookingData.walkin_person || 0)

                    return (view === 'month' && !isPartDate) ?
                      (
                        <div className="price-container">
                          {isCloseAll && <p className={`booking-calendar-price`}>{priceStr}</p>}
                          {!isCloseAll && <p className={`booking-calendar-price`}>
                            {availableRoom > 0 && <span className={`${isToday ? 'text-white' : 'text-success'}`}><i className="fal fa-house-user"></i> {availableRoom}</span>}
                            {(!!currentHotel?.allow_walkin && availableRoom > 0 && availableWalkinGuest > 0) && <span className="ps-1"></span>}
                            {(!!currentHotel?.allow_walkin && availableWalkinGuest > 0) && <span className={`${isToday ? 'text-white' : 'text-success'}`}><i className="fal fa-campground"></i> {availableWalkinGuest}</span>}
                          </p>}
                          {availableRoom <= 0 && availableWalkinGuest <= 0 && <p className={`booking-calendar-price`}><span className="text-danger">เต็ม/full</span></p>}
                        </div>
                      )
                      : null
                  }}
                  value={null}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setMainCurrentStartDate(s.activeStartDate)
                    }
                  }}
                  onClickDay={onClickDay}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal centered size="sm" show={!!availableDataForBooking} onHide={() => setAvailableDataForBooking(null)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="p-2">
          <Modal.Title><h5 className="mb-0 font-weight-bold">เลือกห้องพัก</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1 booking-status">
          <div className="text-start p-0">
            <div className="p-2">
              <span className="text-black font-weight-bold">วันที่เลือก: {moment(availableDataForBooking?.date).format('DD/MM/YYYY')}</span>
            </div>
            <table className="table table-striped">
              <tbody>
                {(availableDataForBooking?.availableRoomQty > 0 && availableDataForBooking?.availableRoomData?.length > 0) && availableDataForBooking?.availableRoomData.map((room, i) => {
                  const roomMedias = _.map(room?.room_medias || [], 'file_path') || []
                  return (
                    <tr key={`booking-info-${i}`}>
                      <td className="text-start">
                        <span className="text-black"><i className="fal fa-house-user"></i>
                          {roomMedias.length > 0 && <img src={roomMedias[0]} className="img-fluid rounded mx-2" style={{ width: 35, height: 35, objectFit: 'cover' }} onClick={()=> setImgForPreview(roomMedias) } />}
                          {room.name_th}
                        </span>
                      </td>
                      <td className="text-end">
                        <button className="btn btn-sm btn-outline-success" onClick={() => {
                          ReactGA.event({
                            category: "Booking Button",
                            action: `${currentHotel?.code}_${room.room_no}_cal_booking_click`,
                            label: `${currentHotel?.name_th} - Booking Button with ${room.name_th}`,
                          });
                          api.request(`rooms/calulateprice`, (data) => {
                            if (data && data.length > 0) {
                              setAvailableDataForBooking(null)
                              onHide(true)
                              const bookDataJson = JSON.stringify([{
                                date: availableDataForBooking?.date,
                                price: data[0]?.price || 0,
                                room_id: room.id,
                              }])
                              navigate(`/once/${room.id}?s_date=${availableDataForBooking?.date}&e_date=${moment(availableDataForBooking?.date).add(1, 'days').format('YYYY-MM-DD')}&book_data=${bookDataJson}`)
                            } else {
                              setAvailableDataForBooking(null)
                              onHide(true)
                              navigate(`/once/${room.id}`)
                            }
                          }, {
                            room_id: room.id,
                            start_date: moment(availableDataForBooking?.date).format('YYYY-MM-DD'),
                            end_date: moment(availableDataForBooking?.date).add(1, 'days').format('YYYY-MM-DD')
                          })
                        }}>
                          BOOKING
                        </button>
                      </td>
                    </tr>
                  )
                })}
                {(availableDataForBooking?.availableWalkinGuestQty > 0) && (
                  <tr>
                    <td className="text-start">
                      <span className="text-black"><i className="fal fa-campground"></i> CAMPING/CAR CAMP</span>
                    </td>
                    <td className="text-end">
                      <button className="btn btn-sm btn-outline-success" onClick={() => {
                        ReactGA.event({
                          category: "Booking Button",
                          action: `${currentHotel?.code}_cal_camping_booking_click`,
                          label: `${currentHotel?.name_th} - Camping Booking Button`,
                        });

                        setAvailableDataForBooking(null)
                        onHide(true)
                        navigate(`/once/camping-booking/${currentHotel?.id}?date_selected=${availableDataForBooking?.date}`)
                      }}>
                        จองพื้นที่
                      </button>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
    </>
  );
};

export default BookingAvailableModal;
