import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import Menu from "./../common/Menu/Menu";
import Services from "./../HomeSection/Services";
import SliderHome from "./../HomeSection/SliderHome";
import TopCtaArea from "./../HomeSection/TopCtaArea";
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber } from './../../utils/functions';
import { toast } from 'react-toastify';
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import ReactGA from 'react-ga4';

export default function BookingStatus() {
  const { id, code } = useParams();
  const [booking, setBooking] = useState({});
  const { hotel, room, campZoneItem, campZone } = booking
  const [filterCode, setFilterCode] = useState(code || '');
  const [filterTel, setFilterTel] = useState('');

  const endDate = new Date(booking?.end_date)
  const endDateStr = endDate.setDate(endDate.getDate() + 1)

  const [addons, setAddons] = useState([]);
  const [items, setItems] = useState([]);

  const addonsText = addons.map((x) => { return `${x?.addons_type?.name_th} (${x?.qty})` })
  const addonsTextStr = (addonsText || []).join(', ')
  const addonsSum = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0

  const bookingDatesStr = booking?.room_id ? items.map((b) => `${moment(b.date).format('DD/MM/YYYY')} (${numberFormat(b.unit_total_price, true)})`).join(', ') :
    items.map((b) => `${moment(b.date).format('DD/MM/YYYY')} (${numberFormat((Number(b.unit_total_price || 0) + Number(b.extra_price || 0)), true)})`).join(', ')

  const fetchBookingData = () => {
    api.request(`bookings/findonebooking`, (data) => {
      setBooking(data || {})
      setItems(data?.booking_items || [])

      if (data.booking_addons && data.booking_addons.length) {
        setAddons(data.booking_addons.map((a) => {
          return {
            ...a,
            addons_id: a.addons_id,
            total_price: a.total_price || 0,
            qty: a.qty || 0
          }
        }))
      }

      if (!!data?.id) {
        ReactGA.send({ hitType: "pageview", page: `${data?.hotel?.code}/bookingstatus`, title: `${data?.hotel?.name_th}-${data?.room_id ? data?.room?.name_th : 'camping'} BookingStatus` });

        ReactGA.event({
          category: "BookingProcess",
          action: `${data?.hotel?.code}_${data?.room_id ? data?.room?.room_no : 'camping'}_booked`,
          label: `Booking Status Success - ${data?.hotel?.code}-${data?.room_id ? data?.room?.room_no : 'camping'}`,
        });
      }

    }, {
      id: id,
      booking_no: filterCode,
      tel: filterTel
    })
  }

  useEffect(() => {
    if (!!id) {
      fetchBookingData()
    }
  }, [id, filterCode, filterTel])


  const warningMsg = `ขณะนี้ระบบ "Once In Memory" ${booking?.payment_state === 'complete' ? 'ได้จัดส่งใบเสร็จรับเงิน' : 'ได้รับข้อมูลการจองห้องพักจากท่านแล้ว โดยเราได้ส่งรายละเอียดถึงท่าน'}ผ่านทาง SMS และอีเมล กรุณาตรวจสอบข้อมูลด้วยนะคะ`
  const outstanding = booking.payment_type === 'full' ? 0 : (Number(booking.net_total || 0) - Number(booking.paid_amount || 0))

  const roomDeposit = !!booking.room_deposits && booking.room_deposits.length > 0 ? booking.room_deposits[0] : null

  const isCloseAll = booking.close_zone_type === 'all'
  const areaName = isCloseAll ? 'จองทุกห้อง' : !!campZoneItem ? `[${campZone?.name_th}] : ${campZoneItem?.name_th}` : (room?.name_th || booking?.campType?.name_th || 'เช่าพื้นที่กางเต็นท์/Car Camp/Walk - In')

  const isCamping = booking.is_walkin

  return (
    <>
      {/* <Menu header_style_2={true} /> */}
      <div className="text-center">
        {!id && <div className=" px-5 py-3">
          <Row className="booking-form-input">
            <Col xs={6} className="ps-5 pe-2">
              <div className="text-start">
                <div>หมายเลขการจอง</div>
                <input
                  type="text"
                  placeholder="ระบุหมายเลขการจอง"
                  value={filterCode}
                  onKeyPress={inputKeyPressOnlyNumber}
                  onChange={(e) => setFilterCode(e.target.value)}
                />
              </div>

            </Col>
            <Col xs={6} className="pe-5 ps-2">
              <div className="text-start">
                <div>หมายเลขโทรศัพท์</div>
                <input
                  type="text"
                  placeholder="ระบุหมายเลขโทรศัพท์"
                  value={filterTel}
                  onKeyPress={inputKeyPressOnlyNumber}
                  onChange={(e) => setFilterTel(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div className="text-warning text-start ps-5 mt-2">
            <Button variant="dark" className="rounded-0 px-4 me-3" size="md" onClick={() => {
              if (!filterCode || !filterTel) {
                toast.warn('กรุณาระบุหมายเลขการจอง และหมายเลขโทรศํพท์ให้ครบถ้วน')
                return
              }

              fetchBookingData()
            }}> <i className="far fa-search"></i> ค้นหา</Button>
            <span>** กรุณาระบุหมายเลขการจอง และหมายเลขโทรศัพท์ให้ครบถ้วน</span>
          </div>
        </div>}
        {!booking?.id && <div className="p-5">
          <h4>ไม่พบข้อมูลการจองที่ค้นหา !</h4>
          <div>{!id ? 'กรุณาตรวจสอบหมายเลขการจอง หรือหมายเลขโทรศัพท์ให้ถูกต้อง' : 'กรุณาตรวจสอบลิงค์ให้ถูกต้อง หรือติดต่อผู้ดูแลระบบ'}</div>
        </div>}

        {!!booking?.id && <div className="p-5 booking-status">
          <p className="text-success text-start text-md-center">{warningMsg}</p>
          <h4>รายละเอียดการจองห้องพัก</h4>
          <div className="text-start px-2 px-md-5 py-3">
            <div className="d-flex align-items-center">
              {!!hotel?.logo_file_path && <img src={hotel?.logo_file_path} className="hotel-logo" alt="img" />}
              <h5 className="mx-2">{hotel?.name_th}</h5>
            </div>
            <Row>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={12}>
                    <span className="title">ห้องพัก/โซน : </span>
                    <span className="detail">{areaName}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">วันที่เข้าพัก : </span>
                    <span className="detail">{moment(booking?.start_date).format('DD/MM/YYYY')} - {moment(endDateStr).format('DD/MM/YYYY')} ({booking?.day_qty} คืน)</span>
                  </Col>
                  {!isCamping && <Col xs={12}>
                    <span className="title">ผู้เข้าพัก : </span>
                    <span className="detail">ผู้ใหญ่ {numberFormat(booking.guest_qty, true)} ท่าน, เด็กไม่เกิน {hotel?.custom_guest_baby_age || 6} ปี {numberFormat(booking.guest_baby_qty, true)} ท่าน</span>
                  </Col>}
                  {isCamping && <Col xs={12}>
                    <span className="title">ผู้เข้าพัก : </span>
                    <span className="detail">ผู้ใหญ่ {numberFormat(booking.guest_qty, true)} ท่าน, เด็ก 6 - 12 ปี {numberFormat(booking.guest_child_qty, true)} ท่าน, เด็กต่ำกว่า 6 ปี {numberFormat(booking.guest_baby_qty, true)} ท่าน{booking.tent_qty > 0 ? `, จำนวนเต็นท์ ${numberFormat(booking.tent_qty, true)} หลัง` : ''}</span>
                  </Col>}
                  {isCamping && <Col xs={12}>
                    <span className="title">ผู้เข้าพัก (เสริม) : </span>
                    <span className="detail">ผู้ใหญ่ {numberFormat(booking.extra_person_qty, true)} ท่าน, เด็ก 6 - 12 ปี {numberFormat(booking.extra_child_qty, true)} ท่าน, เด็กต่ำกว่า 6 ปี {numberFormat(booking.extra_baby_qty, true)} ท่าน</span>
                  </Col>}
                  <Col xs={12}>
                    <span className="title">ชื่อผู้ติดต่อ : </span>
                    <span className="detail">{booking.contact_name}</span>
                  </Col>
                  {!!hotel && hotel.request_customer_tax_info_enabled && <Col xs={12}>
                    <span className="title">เลขประจำตัวผู้เสียภาษี : </span>
                    <span className="detail">{booking.customer_tax_id}</span>
                  </Col>}
                  {!!hotel && hotel.request_customer_tax_info_enabled && <Col xs={12}>
                    <span className="title">ที่อยู่ในการออกใบเสร็จรับเงิน : </span>
                    <span className="detail">{booking.customer_address || '-'}</span>
                  </Col>}
                  <Col xs={12}>
                    <span className="title">หมายเลขโทรศัพท์ : </span>
                    <span className="detail">{booking.contact_tel_1} {!!booking.contact_tel_2 ? `/ ${booking.contact_tel_2}` : ''}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">อีเมล : </span>
                    <span className="detail">{booking.contact_email || '-'}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">ไลน์ไอดี (Line ID) : </span>
                    <span className="detail">{booking.contact_line_id || '-'}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">ยี่ห้อรถ : </span>
                    <span className="detail">{booking.customer_car_model || '-'}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">ทะเบียนรถ : </span>
                    <span className="detail">{booking.customer_car_license_plate || '-'}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <hr className="my-2  d-block d-md-none" style={{ color: 'darkgray' }} />
                <Row>
                  <Col xs={12}>
                    <span className="title">ประเภทการชำระเงิน : </span>
                    {booking.payment_type === 'payonstay' && <span className="badge bg-danger">ชำระวันเข้าพัก</span>}
                    {booking.payment_type === 'deposit' && <span className="badge bg-danger">มัดจำ</span>}
                    {booking.payment_type === 'full' && <span className="badge bg-success">ชำระเต็มจำนวน</span>}
                    {(!!roomDeposit || booking.room_deposit_outstanding > 0) && <span className="badge bg-secondary ms-1">มีค่าประกัน</span>}
                  </Col>
                  {!!roomDeposit && <Col xs={12}>
                    <span className="title">ค่าประกันที่พัก : </span>
                    <span className="detail text-success">{numberFormat(roomDeposit.deposit_amount)} บาท (ชำระแล้ว)</span>
                  </Col>}
                  {booking.room_deposit_outstanding > 0 && <Col xs={12}>
                    <span className="title">ค่าประกันที่พัก : </span>
                    <span className="detail text-danger">{numberFormat(booking.room_deposit_outstanding)} บาท (กรุณาชำระวันเข้าพัก)</span>
                  </Col>}
                </Row>
                <hr className="my-2" style={{ color: 'darkgray' }} />
                <Row>
                  <Col xs={12}>
                    <span className="title">ราคาที่พัก : </span>
                    <span className="detail">{numberFormat(booking.total_price)} บาท {(items?.length > 0 && (booking.room_id || booking.camp_zone_item_id) && !isCloseAll) && <small className="text-muted">- {bookingDatesStr}</small>}</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">ส่วนลด : </span>
                    <span className="detail">{numberFormat(booking.total_discount)} บาท</span>
                  </Col>

                  <Col xs={12}>
                    <span className="title">ค่าที่พักชำระแล้ว : </span>
                    <span className="detail text-success">{numberFormat(booking.paid_amount)} บาท</span>
                  </Col>
                  <Col xs={12}>
                    <span className="title">ค่าที่พักค้างชำระ : </span>
                    <span className={`detail ${outstanding > 0 ? 'text-danger' : ''}`}>{numberFormat(outstanding)} บาท {outstanding > 0 && <small className="text-warning"> *กรุณาชำระเงินส่วนที่เหลือในวันเข้าพัก</small>}</span>
                  </Col>
                </Row>
                <hr className="my-2" style={{ color: 'darkgray' }} />
                <Row>
                  {!!roomDeposit && <Col xs={12}>
                    <span className="title">ยอดชำระสุทธิ : </span>
                    <span className="badge bg-success">{numberFormat(Number(booking.paid_amount || 0) + Number(roomDeposit.deposit_amount || 0))} บาท</span>
                  </Col>}
                  {booking.room_deposit_outstanding > 0 && <Col xs={12}>
                    <span className="title">ยอดชำระวันเข้าพัก : </span>
                    <span className="badge bg-danger">{numberFormat(Number(outstanding || 0) + Number(booking.room_deposit_outstanding || 0))} บาท</span>
                  </Col>}
                  <Col xs={12}>
                    <span className="title">สถานะการชำระเงิน : </span>
                    {booking.payment_state === 'waiting' && <span className="badge bg-warning">รอการตรวจสอบ</span>}
                    {booking.payment_state === 'waiting-approved' && <span className="badge bg-danger">ชำระเงินวันเข้าพัก</span>}
                    {booking.payment_state === 'complete' && <span className="badge bg-success">ชำระเงินเรียบร้อยแล้ว</span>}
                    {booking.payment_state === 'partial' && <span className="badge bg-info">ชำระเงินมัดจำเรียบร้อยแล้ว</span>}
                  </Col>
                </Row>
                <div>
                  <span className="title">หมายเหตุ : </span>
                  <span className="detail">{booking.booking_note || '-'}</span>
                </div>
                {(addons && addons.length > 0) && <div>
                  <span className="title">อุปกรณ์เสริม : </span>
                  <span className="detail">{addonsTextStr || '-'}  ราคารวม {numberFormat(addonsSum)} บาท</span>
                </div>}
              </Col>
            </Row>
          </div>
        </div>}
      </div >
      <OnceFooter />
    </>
  );
}
