import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import { useParams, useLocation } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger, Tooltip, Tab, Table } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import { toast } from 'react-toastify';
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import { Lightbox } from "react-modal-image";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactGA from 'react-ga4';

export default function CampingZoneBooking() {
  const navigate = useNavigate();
  const { camp_item_id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qDateSelected = searchParams.get('date_selected');
  const { handleSetCurrentHotel, currentHotel } = useContext(AppContext);
  const hotel_id = currentHotel?.id || null;
  const [campItemData, setCampItemData] = useState(null);

  const [currentStartDate, setCurrentStartDate] = useState(new Date());
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [showCouponSuggestionModal, setShowCouponSuggestionModal] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [customPrices, setCustomPrices] = useState([])

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [couponCode, setCouponCode] = useState('');
  const [discount, setDiscount] = useState(null);

  const [addons, setAddons] = useState([]);
  const [addonOptions, setAddonsOptions] = useState([]);

  const [campingType, setCampingType] = useState([]);
  const [walkinSummary, setWalkinSummary] = useState([]);

  const [customTentConfigs, setCustomTentConfigs] = useState([]);

  const [bookingItems, setBookingItems] = useState([]);

  const dayQty = moment(endDate).diff(moment(startDate), 'days') || 0

  const [form, setForm] = useState({
    payment_type: 'full',
    payment_state: 'waiting',
    state: 'payment',
    booking_date: new Date(),
    hotel_id: hotel_id,
    room_id: null,
    // camp_zone_id: null,
    // camp_zone_item_id: null,
    // camping_zone_type: null,
    //extra_person_qty
    //extra_child_qty
    //extra_person_total_price
    total_price: 0,
    booking_note: '',
    slip_file: null
  });

  let discountPrice = 0

  if (discount && discount?.coupon) {
    const c = discount?.coupon
    const couponDiscountPrice = Number(c.discount_price) || 0

    if (c.discount_type === 'static') {
      discountPrice = couponDiscountPrice
    } else {
      discountPrice = (Number(form?.total_price || 0) || 0) * (couponDiscountPrice / 100)
    }

    if (discountPrice > Number(form.total_price)) {
      discountPrice = Number(form.total_price)
    }

    if (c.discount_type === 'percent') {
      const dayLimit = Number(c.discount_day_limit || 0)
      const discountLimit = Number(c.discount_price_limit || 0)

      if (dayLimit > 0 && dayLimit < dayQty) {
        discountPrice = (Number(discountPrice || 0)) / dayQty * dayLimit
      }

      if (discountLimit > 0 && discountPrice > discountLimit) {
        discountPrice = discountLimit
      }
    }
  }

  const sumAddons = _.sumBy(addons || [], (x) => { return Number(x.total_price || 0) }) || 0;
  const payAmount = Number(form.total_price) - discountPrice + sumAddons

  const fetchCampItemData = (campItemId) => {
    api.request(`camp-zones/items/${campItemId}`, (data) => {
      console.log(data)
      setCampItemData(data || null)

      if (!currentHotel || !currentHotel?.id) {
        handleSetCurrentHotel(data?.hotel || {})
      }
    })
  }

  const fetchPriceGroup = (campItemId) => {
    // api.request(`/rooms/${roomId}/price`, (data) => {
    //   const r = data?.room || {}
    //   setGroupPrice(data?.price_data || null)
    // }, {})
  }

  const fetchBookingsByDate = (date, hotelId, campItemId) => {
    if (!date || !campItemId) return
    api.request('bookings/all-check-room', (data) => {
      setBookings(data || [])
    }, {
      hotel_id: hotelId,
      camp_zone_item_id: campItemId,
      start_date: moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchHoliday = (hotelId, y) => {
    api.request('/holidays', (data) => {
      setHolidays(data || [])
    }, { hotel_id: hotelId, enabled: true, holiday_year: y })
  }

  const fetchCustomPriceItems = (date, roomId) => {
    // api.request(`room-prices/allcriteria`, (data) => {
    //   setCustomPrices(data || [])
    // }, {
    //   enabled: true,
    //   room_id: roomId,
    //   start_date: moment(date).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
    //   end_date: moment(date).endOf('month').add(7, 'days').format('YYYY-MM-DD')
    // })
  }

  const fetchHotelAddonsItem = (hId) => {
    api.request(`hotel-addons/all`, (data) => {
      setAddonsOptions(data?.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }) || [])
    }, { hotel_id: hId, enabled: true, is_non_inventory: true })
  }

  const fetchCampingTypes = (hId) => {
    api.request(`camp-types/all`, (res) => {
      setCampingType(res?.map((item) => {
        return {
          label: item.name_th,
          value: item.id,
          _price: item.default_price || 0
        }
      }) || [])
    }, {
      enabled: true,
      hotel_id: hId
    })
  }

  const validateAddons = () => {
    if (!addons.length) {
      return true
    }

    let l = (addons.filter((x) => !!x.addons_id)) || []

    return l.length === addons.length
  }

  const personLimit = Number(campItemData?.person_limit || campItemData?.campZone?.person_limit) || 0
  const extraPersonLimit = Number(campItemData?.extra_person_limit || campItemData?.campZone?.extra_person_limit) || 0
  const extraPersonPrice = Number(campItemData?.extra_person_price || campItemData?.campZone?.extra_person_price) || 0
  const extraPersonChildPrice = Number(campItemData?.extra_person_child_price || campItemData?.campZone?.extra_person_child_price) || 0
  const extraPersonHolidayPrice = Number(campItemData?.extra_person_holiday_price || campItemData?.campZone?.extra_person_holiday_price) || 0
  const extraPersonChildHolidayPrice = Number(campItemData?.extra_person_child_holiday_price || campItemData?.campZone?.extra_person_child_holiday_price) || 0

  useEffect(() => {
    const extra_g_qty = Number(form.extra_person_qty || 0)
    const extra_c_qty = Number(form.extra_child_qty || 0)
    const _dates = loopDateFromStartDateToEndDate(startDate, endDate)

    if (extra_g_qty > 0 || extra_c_qty > 0) {
      const bookingItems = _dates.map((d) => {
        const _extraGuestPrice = getGuestPricePerDayWithExtraPerson(d, 'guest')
        const _extraChildPrice = getGuestPricePerDayWithExtraPerson(d, 'child')

        return {
          date: moment(d).format('YYYY-MM-DD'),
          price: getGuestPricePerDay(d) || 0,
          extra_price: (_extraGuestPrice * extra_g_qty) + (_extraChildPrice * extra_c_qty),
          camp_zone_item_id: camp_item_id,
          camp_zone_id: campItemData?.campZone?.id,
          is_camp_zone: true,
          guest_qty: Number(form.guest_qty || 0) + Number(form.guest_child_qty || 0) + extra_g_qty + extra_c_qty,
        }
      }) || []

      // const sumPrice = _dates.reduce((acc, d) => {
      //   const _price = getGuestPricePerDay(d)
      //   const _extraGuestPrice = getGuestPricePerDayWithExtraPerson(d, 'guest')
      //   const _extraChildPrice = getGuestPricePerDayWithExtraPerson(d, 'child')
      //   const _pricePerDay = _price + (_extraGuestPrice * extra_g_qty) + (_extraChildPrice * extra_c_qty)
      //   return acc + _pricePerDay
      // }, 0)

      // const sumExtraPersonPrice = _dates.reduce((acc, d) => {
      //   const _extraGuestPrice = getGuestPricePerDayWithExtraPerson(d, 'guest')
      //   const _extraChildPrice = getGuestPricePerDayWithExtraPerson(d, 'child')
      //   return acc + ((_extraGuestPrice * extra_g_qty) + (_extraChildPrice * extra_c_qty))
      // }, 0)

      const sumPrice = _.sumBy(bookingItems, (x) => { return Number(x.price || 0) }) || 0
      const sumExtraPersonPrice = _.sumBy(bookingItems, (x) => { return Number(x.extra_price || 0) }) || 0

      setBookingItems(bookingItems)

      setForm({ ...form, total_price: sumPrice + sumExtraPersonPrice, extra_person_total_price: sumExtraPersonPrice })
    } else {
      const bookingItems = _dates.map((d) => {
        return {
          date: moment(d).format('YYYY-MM-DD'),
          price: getGuestPricePerDay(d) || 0,
          extra_price: 0,
          camp_zone_item_id: camp_item_id,
          camp_zone_id: campItemData?.campZone?.id,
          is_camp_zone: true,
          guest_qty: Number(form.guest_qty || 0) + Number(form.guest_child_qty || 0),
        }
      }) || []

      setBookingItems(bookingItems)
      const sumPrice = _.sumBy(bookingItems, (x) => { return Number(x.price || 0) }) || 0
      setForm({ ...form, total_price: sumPrice })
    }

  }, [dayQty, startDate, endDate, form.extra_person_qty, form.extra_child_qty])


  useEffect(() => {
    if (!camp_item_id) return

    fetchCampItemData(camp_item_id)
    fetchPriceGroup(camp_item_id)
    // fetchBookingsByDate(currentStartDate, hotel_id, camp_item_id)
    // fetchHoliday(hotel_id, currentYear)
    fetchCustomPriceItems(currentStartDate, camp_item_id)
  }, [camp_item_id])

  useEffect(() => {
    if (!camp_item_id || !hotel_id) return
    fetchBookingsByDate(currentStartDate, hotel_id, camp_item_id)
    fetchHoliday(hotel_id, currentYear)
  }, [camp_item_id, hotel_id])

  useEffect(() => {
    if (!hotel_id) {
      return
    }
    fetchHoliday(hotel_id, currentYear)
    // fetchHotel(hotel_id)
    fetchHotelAddonsItem(hotel_id)
    fetchCampingTypes(hotel_id)
  }, [hotel_id])

  useEffect(() => {
    if (!!camp_item_id && !!currentStartDate) {
      fetchBookingsByDate(currentStartDate, hotel_id, camp_item_id)
      fetchCustomPriceItems(currentStartDate, camp_item_id)
    }
  }, [currentStartDate])

  // useEffect(() => {
  //   if (!!qDateSelected) {
  //     setCurrentStartDate(new Date(qDateSelected))
  //     setStartDate(moment(qDateSelected).toDate())
  //     setEndDate(moment(qDateSelected).add(1, 'days').toDate())
  //   }
  // }, [qDateSelected])

  useEffect(() => {
    if (!!hotel_id) {
      fetchHoliday(hotel_id, currentYear)
    }
  }, [currentYear])

  function loopDateFromStartDateToEndDate(start, end) {
    const diff = moment(end).diff(moment(start), 'days')
    if (diff <= 0) return []

    const arr = []

    for (let i = 0; i < diff; i++) {
      const d = moment(start).add(i, 'days').toDate()
      arr.push(d)
    }

    return arr
  }

  function checkBooked(date) {
    return bookings.findIndex((b) => {
      return moment(date).isBetween(moment(b.start_date), moment(b.end_date), null, '[]')
    }) > -1
  }

  function getBookingData(date) {
    return bookings.find((b) => {
      return moment(date).isBetween(moment(b.start_date), moment(b.end_date), null, '[]')
    }) || null
  }

  function checkCloseRoom(date) {
    return customPrices.findIndex((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !!c.block_day
    }) > -1
  }

  function getCustomPriceData(date) {
    return customPrices.find((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !c.block_day && !!c.price
    }) || null
  }

  function checkCustomPrice(date) {
    return customPrices.findIndex((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && !c.block_day && !!c.price
    }) > -1
  }

  function getRoomSelectedData(date) {
    // const isHoliday = checkHoliday(date)
    // const customPrice = getCustomPriceData(date)

    // let price = isWeekEnd ? normalWeekendPrice : normalPrice

    // if (!!customPrice) {
    //   price = customPrice.price
    // }

    return {
      date: moment(date).format('YYYY-MM-DD'),
      // price: price,
      // room_id: room_id
    }
  }

  function checkRoomNotAvailable(start_date, end_date) {
    const dates = loopDateFromStartDateToEndDate(start_date, end_date) || []

    const isNotAvailable = dates.some((d) => {
      return checkCloseRoom(d) || checkBooked(d)
    })

    if (isNotAvailable) {
      return []
    } else {
      return dates.map((d) => {
        return getRoomSelectedData(d)
      }) || []
    }
  }

  function getHolidayData(date) {
    return holidays.find((h) => {
      return h.date === moment(date).format('YYYY-MM-DD') || null
    })
  }

  function checkHoliday(date) {
    return holidays.findIndex((h) => {
      return h.date === moment(date).format('YYYY-MM-DD')
    }) > -1
  }

  function getCustomTentConfig(date) {
    return customTentConfigs.find((c) => {
      return moment(date).isSame(moment(c.date), 'day') && !!c.enabled && c.price > 0
    }) || null
  }

  function getGuestPricePerDay(date) {
    const holidayData = getHolidayData(date)
    const customTentConfig = getCustomTentConfig(date)
    const pricePerDay = Number(campItemData?.price || campItemData?.campZone?.base_price || 0)
    const holidayPricePerDay = Number(campItemData?.holiday_price || campItemData?.campZone?.base_holiday_price || pricePerDay)

    if (!!customTentConfig && Number(customTentConfig.price > 0)) {
      return customTentConfig.price
    }

    if (!!holidayData) {
      return holidayPricePerDay
    }

    return pricePerDay
  }

  function getGuestPricePerDayWithExtraPerson(date, type = 'guest') {
    const holidayData = getHolidayData(date)
    if (type === 'guest') {
      return !!holidayData ? extraPersonHolidayPrice : extraPersonPrice
    }

    if (type === 'child') {
      return !!holidayData ? extraPersonChildHolidayPrice : extraPersonChildPrice
    }

    return 0
  }

  function tileClassName({ date, view }) {
    if (view !== 'month') return null;
    const classes = ['booking-calendar-date'];
    const isPartDate = moment(date).isBefore(moment(), 'day')

    if (!isPartDate) {
      if (checkHoliday(date)) {
        classes.push('custom-holiday');
      }

      if (checkBooked(date)) {
        classes.push('custom-booked');
      }

      if (checkCloseRoom(date)) {
        classes.push('custom-close-room');
      }

      if (checkCustomPrice(date)) {
        classes.push('custom-price');
      }

      if (endDate && moment(endDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
        classes.push('react-calendar__tile--active');
      }
    } else {
      classes.push('part-date');
    }

    return classes.join(' ');
  }

  const popoverBottom = (
    <Popover id="popover-positioned-bottom" title="Popover bottom">
      <div className="p-2">
        <span className="text-success">เลือกวันที่เข้าพัก โดยกดวันเช็คอิน และวันเช็คเอาท์บนปฏิทิน </span><span className="text-black">(วันที่ถูกเลือกจะแสดงแถบสีดำ)</span>
      </div>
    </Popover>
  );

  const checkTotalPerson = (value, type = 'guest') => {
    if (type === 'guest') {
      const totalPerson = Number(value) + Number(form.guest_child_qty || 0)
      if (totalPerson > personLimit) {
        toast.error(`จำนวนผู้เข้าพักเกินจำนวนที่กำหนด (${personLimit} ท่าน) กรุณาระบุจำนวนเสริมผู้เข้าพัก`)
        return false
      }
      return true
    }

    if (type === 'child') {
      const totalPerson = Number(form.guest_qty || 0) + Number(value)
      if (totalPerson > personLimit) {
        toast.error(`จำนวนผู้เข้าพักเกินจำนวนที่กำหนด (${personLimit} ท่าน) กรุณาระบุจำนวนเสริมผู้เข้าพัก`)
        return false
      }
      return true
    }
  }

  const checkTotalExtraPerson = (value, type = 'guest') => {
    if (type === 'guest') {
      const totalPerson = Number(value) + Number(form.extra_child_qty || 0)
      if (totalPerson > extraPersonLimit) {
        toast.error(`จำนวนผู้เข้าพักเสริมต้องไม่เกิน ${extraPersonLimit} ท่าน`)
        return false
      }
      return true
    }

    if (type === 'child') {
      const totalPerson = Number(form.extra_person_qty || 0) + Number(value)
      if (totalPerson > extraPersonLimit) {
        toast.error(`จำนวนผู้เข้าพักเสริมต้องไม่เกิน ${extraPersonLimit} ท่าน`)
        return false
      }
      return true
    }
  }

  return (
    <>
      {(!currentHotel || !currentHotel?.allow_walkin) ?
        <div className="text-center py-5 px-2 px-md-5">
          <h4>ขออภัย การจองพื้นที่กางเต็นท์/Car Camp ไม่สามารถใช้งานได้ในขณะนี้</h4>
        </div>
        :
        <div className="text-center py-5 px-2 px-md-5">
          <h4>เช่าพื้นที่ [{campItemData?.code}] : {campItemData?.name_th}</h4>
          <Row>
            <Col xs={12} md={6}>
              <div className={`mt-2 mb-4`}>
                <div className="mb-2 d-flex title-btn">
                  <span onClick={() => setCurrentStartDate(new Date())} className="text-black border rounded py-1 px-2"><i className="fal fa-calendar"></i> TODAY</span>
                  <span className="ms-3 rounded" style={{ backgroundColor: '#FFCB96', color: '#000000', padding: '5px 10px' }} onClick={() => {
                    setShowSuggestionModal(true)
                    ReactGA.event({
                      category: "Suggestion Button",
                      action: `suggestion_calendar_click`,
                      label: `Suggestion Camping Calendar Click`,
                    });
                  }}>วิธีกดปฏิทินเพื่อจองห้องพัก
                    <i className="fas fa-info-circle ps-2 text-danger" style={{ fontSize: 18 }}></i>
                  </span>
                </div>
                <Calendar
                  selectRange={true}
                  showDoubleView={false}
                  minDate={new Date()}
                  calendarType={'hebrew'}
                  locale={'th-TH'}
                  activeStartDate={currentStartDate}
                  showNavigation={true}
                  tileClassName={tileClassName}
                  tileContent={({ activeStartDate, date, view }) => {
                    const holidayData = getHolidayData(date)
                    const customPrice = getCustomPriceData(date)

                    let price = campItemData?.price || campItemData?.campZone?.base_price || 0

                    if (!!holidayData) {
                      price = campItemData?.holiday_price || campItemData?.campZone?.base_holiday_price || price
                    }

                    let priceStr = numberFormat(price, true)
                    const basePrice = price

                    if (!!customPrice) {
                      priceStr = numberFormat(customPrice.price, true)
                      price = customPrice.price
                    }

                    if (checkBooked(date)) {
                      priceStr = 'booked'
                    }

                    if (endDate && moment(endDate).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) {
                      priceStr = 'เช็คเอาท์'
                    }

                    if (checkCloseRoom(date)) {
                      priceStr = 'ปิดบริการ'
                    }

                    const showBasePrice = !!customPrice && customPrice.price < basePrice

                    return (view === 'month') ?
                      (
                        (!!holidayData) ?
                          <OverlayTrigger delay={{ show: 300, hide: 0 }} overlay={<Tooltip>{holidayData.title_th}</Tooltip>} placement="top">
                            <div className="price-container">
                              {showBasePrice && <small className="normal-price-small">{numberFormat(basePrice, true)}</small>}
                              <p className={`booking-calendar-price`}>{priceStr}
                                <i className="fal fa-calendar ms-1"></i>
                              </p>
                            </div>
                          </OverlayTrigger>
                          :
                          <div className="price-container">
                            {showBasePrice && <small className="normal-price-small">{numberFormat(basePrice, true)}</small>}
                            <p className={`booking-calendar-price`}>{priceStr}</p>
                          </div>
                      )
                      : null
                  }}
                  value={[startDate, endDate]}
                  onChange={(e) => {
                    if (Array.isArray(e) && e.length === 2) {
                      const selectedList = checkRoomNotAvailable(e[0], e[1]) || []
                      if (!selectedList.length) {
                        setStartDate(null)
                        setEndDate(null)
                        return
                      }
                      setStartDate(e[0])
                      setEndDate(e[1])
                    }
                  }}
                  onClickDay={(d) => {
                    const startDateSelected = d
                    const nextDay = moment(d).add(1, 'days').toDate()

                    const selectedList = checkRoomNotAvailable(startDateSelected, nextDay) || []
                    if (!selectedList.length) {
                      setStartDate(null)
                      setEndDate(null)
                      return
                    }
                    setStartDate(startDateSelected)
                    setEndDate(nextDay)
                  }}
                  onActiveStartDateChange={(s) => {
                    if (s?.activeStartDate) {
                      setCurrentStartDate(s.activeStartDate)
                      setCurrentYear(moment(s.activeStartDate).format('YYYY'))
                    }
                  }}
                />
              </div>
              {campItemData?.campZone?.facilities?.length > 0 && <div className="py-2 text-start">
                <h5>สิ่งอำนวยความสะดวก</h5>
                {campItemData?.campZone?.facilities?.map((h, i) => {
                  return (
                    <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-2">
                      <i className="fal fa-check text-success me-2"></i>
                      <span className="mb-0">{h.name_th}</span>
                    </div>
                  )
                })}
              </div>}
            </Col>
            <Col xs={12} md={6} className="text-start mt-0 mt-md-5 booking-form-input">
              <div>
                <p className="mb-1">{campItemData?.description_th || campItemData?.campZone?.description_th}</p>
                <div className="text-black">
                  {!campItemData?.size_custom && <span>ขนาดพื้นที่ {campItemData?.size_width || campItemData?.campZone?.base_size_width} x {campItemData?.size_height || campItemData?.campZone?.base_size_height}</span>}
                  {!!campItemData?.size_custom && <span>ขนาดพื้นที่ {campItemData?.size_custom}</span>}
                </div>
                <div className="text-black">รองรับผู้เข้าพัก สูงสุด {numberFormat((campItemData?.person_limit || campItemData?.campZone?.person_limit), true)} ท่าน</div>
                <div className="border border-success rounded p-2 mt-2">
                  <h5>ราคา</h5>
                  <div>วันธรรมดา {numberFormat((campItemData?.price || campItemData?.campZone?.base_price), true)} บาท / ล็อก</div>
                  <div>วันหยุดนักขัตฤกษ์ {numberFormat((campItemData?.holiday_price || campItemData?.campZone?.base_base_price), true)} บาท / ล็อก</div>

                  {extraPersonLimit > 0 && <div className="mt-2">
                    <div className="bg-danger mb-2" style={{ height: 1, width: '100%' }}></div>
                    <div className="text-danger">* เสริมผู้เข้าพักได้สูงสุด {numberFormat(extraPersonLimit, true)} ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันธรรมดา ผู้ใหญ่ {numberFormat(extraPersonPrice, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันธรรมดา เด็กโต {numberFormat(extraPersonChildPrice, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันหยุดนักขัตฤกษ์ ผู้ใหญ่ {numberFormat(extraPersonHolidayPrice, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันหยุดนักขัตฤกษ์ เด็กโต {numberFormat(extraPersonChildHolidayPrice, true)} บาท / ท่าน</div>
                  </div>}
                </div>
              </div>
              <div className="d-flex mt-2">
                <strong className="text-black">วันที่เข้าพัก : </strong> {(!!startDate && !!endDate) && <span className="ms-2">{moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")} ({dayQty} คืน)</span>} {(!startDate || !endDate) && <span className="ms-2 text-warning">กรุณาเลือกวันที่เข้าพัก</span>}
              </div>
              <strong className="text-black mt-3 mb-1">จำนวนผู้เข้าพัก</strong>
              <Row>
                <Col xs={4}>
                  <label>ผู้ใหญ่</label>
                  <input
                    type="number"
                    name="guest_qty"
                    placeholder="ผู้ใหญ่"
                    min={1}
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_qty || ""}
                    onChange={(e) => {
                      if (checkTotalPerson(e.target.value, 'guest')) {
                        setForm({ ...form, guest_qty: e.target.value })
                      }
                    }}
                    required
                  />
                </Col>
                <Col xs={4}>
                  <label>เด็ก 6 - 12 ปี</label>
                  <input
                    type="number"
                    name="guest_child_qty"
                    placeholder="เด็ก 6 - 12 ปี"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_child_qty || ""}
                    onChange={(e) => {
                      if (checkTotalPerson(e.target.value, 'child')) {
                        setForm({ ...form, guest_child_qty: e.target.value })
                      }
                    }}
                  />
                </Col>
                <Col xs={4}>
                  <label>เด็กต่ำกว่า 6 ปี</label>
                  <input
                    type="number"
                    name="guest_baby_qty"
                    placeholder="เด็กต่ำกว่า 6 ปี"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.guest_baby_qty || ""}
                    onChange={(e) => setForm({ ...form, guest_baby_qty: e.target.value })}
                  />
                </Col>
              </Row>
              {extraPersonLimit > 0 && <div className="mt-3">
                <strong className="text-danger mb-1">เสริมผู้เข้าพัก</strong>
                <Row>
                  <Col xs={4}>
                    <label>ผู้ใหญ่ (เสริม) <span className="text-warning">฿{numberFormat(extraPersonPrice, true)}+</span></label>
                    <input
                      type="number"
                      name="extra_guest_qty"
                      placeholder="ผู้ใหญ่"
                      min={1}
                      onKeyPress={inputKeyPressOnlyNumber} value={form.extra_person_qty || ""}
                      onChange={(e) => {
                        if (checkTotalExtraPerson(e.target.value, 'guest')) {
                          setForm({ ...form, extra_person_qty: e.target.value })
                        }
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <label>เด็ก 6 - 12 ปี (เสริม) <span className="text-warning">฿{numberFormat(extraPersonChildPrice, true)}+</span></label>
                    <input
                      type="number"
                      name="extra_guest_child_qty"
                      placeholder="เด็ก 6 - 12 ปี"
                      onKeyPress={inputKeyPressOnlyNumber} value={form.extra_child_qty || ""}
                      onChange={(e) => {
                        if (checkTotalExtraPerson(e.target.value, 'child')) {
                          setForm({ ...form, extra_child_qty: e.target.value })
                        }
                      }}
                    />
                  </Col>
                  <Col xs={4}>
                    <label>เด็กต่ำกว่า 6 ปี (เสริม)</label>
                    <input
                      type="number"
                      name="extra_guest_baby_qty"
                      placeholder="เด็กต่ำกว่า 6 ปี"
                      onKeyPress={inputKeyPressOnlyNumber} value={form.extra_baby_qty || ""}
                      onChange={(e) => setForm({ ...form, extra_baby_qty: e.target.value })}
                    />
                  </Col>
                </Row>
              </div>}
              <div className="mt-3">
                <div>
                  {bookingItems?.length > 0 && <Table striped bordered size="sm">
                    <thead>
                      <tr>
                        <th className="text-center">วันที่</th>
                        <th className="text-center">ราคา (ปกติ)</th>
                        <th className="text-center text-danger">ราคา (เสริมผู้เข้าพัก)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bookingItems.map((b, i) => {
                        return (
                          <tr key={`booking-item-${i + 1}`}>
                            <td className="text-center">{moment(b.date).format("DD/MM/YYYY")}</td>
                            <td className="text-end">{numberFormat(b.price)}</td>
                            <td className="text-danger text-end">{numberFormat(b.extra_price, true, '-')}</td>
                          </tr>
                        )
                      })}
                      <tr className="fw-bold">
                        <td className="text-center">รวม</td>
                        <td className="text-end">{numberFormat(_.sumBy(bookingItems, (x) => { return Number(x.price || 0) }))}</td>
                        <td className="text-danger text-end">{numberFormat(_.sumBy(bookingItems, (x) => { return Number(x.extra_price || 0) }), true, '-')}</td>
                      </tr>
                    </tbody>
                  </Table>}
                </div>
                <h4 className="text-black">ราคารวม :  <span className="ms-2 text-success">{numberFormat(form.total_price)} บาท</span></h4>
              </div>
              <div className="mt-2">
                <strong className="text-warning">ส่วนลด/คูปอง (ถ้ามี)</strong>

                {!discount && <Row className="mb-1">
                  <Col xs={8}>
                    <input
                      type="text"
                      name="coupon_code"
                      placeholder="ระบุโค้ดส่วนลด"
                      value={couponCode || ""}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Button disabled={!couponCode?.length} className="btn btn-sm btn-success" onClick={() => {
                      setDiscount(null)

                      api.request(`coupons/items/${couponCode}`, (data, suc) => {
                        if (!suc) {
                          return
                        }

                        if (data.error) {
                          toast.warn(data.message)
                          return
                        }
                        setDiscount(data.coupon)
                      }, {})
                    }}>
                      ใช้คูปอง
                    </Button>
                    <p className="mb-0 text-danger" onClick={() => setShowCouponSuggestionModal(true)}><i className="fas fa-info-circle pe-1 text-danger" style={{ fontSize: 14 }}></i><small>วิธีการใช้คูปอง</small></p>
                  </Col>
                </Row>}
                {!!discount && <Row className="mb-1">
                  <Col xs={8}>
                    <strong className="text-success"> ส่วนลดที่ได้รับ : {numberFormat(discountPrice)} บาท</strong>
                  </Col>
                  <Col xs={4}>
                    <Button className="btn btn-sm btn-danger" onClick={() => {
                      setCouponCode('')
                      setDiscount(null)
                    }}>
                      ยกเลิกคูปอง
                    </Button>
                  </Col>
                </Row>}
              </div>
            </Col>
          </Row>
          <div>
            <div className="booking-form-input my-3 text-start">
              <Row className="mb-1">
                <Col xs={6} md={3}>
                  <div className="top-label" style={{ flex: 1 }}>
                    <Select
                      options={campingType}
                      onChange={(option) => {
                        setForm({ ...form, camp_type_id: option.value })
                      }}
                      className={`flex-fill mt-2  ${!!form?.camp_type_id ? '' : 'camp-type-select-warning'}`}
                      placeholder="*ประเภทการเข้าพัก"
                      value={optionValue(form?.camp_type_id, campingType)}
                      classNamePrefix="react-select-custom"
                      id={`camp_type_select`}
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="number"
                    name="tent_qty"
                    placeholder="จำนวนเต็นท์/รถ"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.tent_qty || ""}
                    onChange={(e) => setForm({ ...form, tent_qty: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="booking_contact_name"
                    placeholder="ระบุชื่อผู้ติดต่อ"
                    value={form.contact_name || ""}
                    onChange={(e) => setForm({ ...form, contact_name: e.target.value })}
                    required
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="booking_tel_1"
                    placeholder="เบอร์ติดต่อ (กรุณาระบุเฉพาะตัวเลข)"
                    onKeyPress={inputKeyPressOnlyNumber} value={form.contact_tel_1 || ""}
                    onChange={(e) => {
                      if (e.nativeEvent?.inputType === "insertFromPaste" && e.target.value) {
                        const t = e.target.value.replace(/\D/g, '')
                        setForm({ ...form, contact_tel_1: t })
                      } else {
                        setForm({ ...form, contact_tel_1: e.target.value })
                      }
                    }}
                    required
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="contact_line_id"
                    placeholder="ไลน์ไอดี/LINE ID (ถ้ามี)"
                    value={form.contact_line_id || ""}
                    onChange={(e) => setForm({ ...form, contact_line_id: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="contact_email"
                    placeholder="อีเมล (ถ้ามี)"
                    value={form.contact_email || ""}
                    onChange={(e) => setForm({ ...form, contact_email: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="customer_car_model"
                    placeholder="ยี่ห้อรถ"
                    value={form.customer_car_model || ""}
                    onChange={(e) => setForm({ ...form, customer_car_model: e.target.value })}
                  />
                </Col>
                <Col xs={6} md={3}>
                  <input
                    className="mt-2"
                    type="text"
                    name="customer_car_license_plate"
                    placeholder="ทะเบียนรถ"
                    value={form.customer_car_license_plate || ""}
                    onChange={(e) => setForm({ ...form, customer_car_license_plate: e.target.value })}
                  />
                </Col>
              </Row>
              <textarea
                className="mt-2"
                type="text"
                name="booking_note"
                placeholder="ความต้องการเพิ่มเติม"
                value={form.booking_note || ""}
                onChange={(e) => setForm({ ...form, booking_note: e.target.value })}
              />
            </div>
            {addonOptions && addonOptions.length > 0 && <div className="mt-2 text-start">
              <strong className="text-warning">รายการอุปกรณ์เสริม(ถ้ามี)</strong>
              <div className="mb-3">
                {addons.map((ad, i) => {
                  return (
                    <div className="mt-3" key={`addons-list-${i}`}>
                      <div className="d-flex mb-3 ">
                        <div className="top-label" style={{ flex: 1 }}>
                          <Form.Label htmlFor={`addons_name_${i}`}>อุปกรณ์เสริม{i + 1}</Form.Label>
                          <Select
                            options={addonOptions}
                            onChange={(option) => {
                              let ads = [...addons]
                              ad.addons_id = option.value
                              ad.unit_price = option._price
                              ad.total_price = ((Number(option._price) * Number(ad.qty)) || 0)
                              ads[i] = ad
                              setAddons(ads)
                            }}
                            className={'flex-fill'}
                            placeholder="เลือกอุปกรณ์เสริม"
                            value={optionValue(ad.addons_id, addonOptions)}
                            classNamePrefix="react-select"
                            id={`addons_name_${i}`}
                          />
                        </div>
                        <div className="top-label ms-2" style={{ width: 70 }}>
                          <Form.Label htmlFor={`addons_qty_${i}`}>จำนวน</Form.Label>

                          <Form.Control id={`addons_qty_${i}`} type="number" min="1" onKeyPress={inputKeyPressOnlyNumber} value={ad.qty || ""} onChange={(e) => {
                            let ads = [...addons]
                            ad.qty = e.target.value
                            ad.total_price = Number(e.target.value) * Number(ad.unit_price)
                            ads[i] = ad
                            setAddons(ads)
                          }} />
                        </div>
                        <div className="top-label ms-2" style={{ width: 100 }}>
                          <Form.Label htmlFor={`addons_price_${i}`}>ราคา</Form.Label>
                          <Form.Control id={`addons_price_${i}`} type="text" value={numberFormat(ad.total_price) || ""} disabled />
                        </div>
                        <Button variant="outline-danger" disabled={submitting} className="btn-icon btn-icon-only ms-2" onClick={() => {
                          let ads = [...addons]
                          ads.splice(i, 1)
                          setAddons(ads)
                        }}>
                          <i className="fa fa-trash" />
                        </Button>
                      </div>
                    </div>
                  )
                })}
                {addons && addons.length > 0 && <p className="text-primary">รวมค่าบริการอุปกรณ์เสริม {numberFormat(sumAddons || 0)}</p>}
                <Button variant="light" disabled={submitting} className="btn-icon btn-sm" onClick={() => {
                  let ads = [...addons]
                  ads.push({ name: '', qty: 1, total_price: 0, unit_price: 0 })
                  setAddons(ads)
                }}>
                  <i className="fa fa-plus me-2" />
                  เพิ่มอุปกรณ์เสริม
                </Button>
              </div>
            </div>}
            <hr />
            <div className="text-start">
              {!!currentHotel.payment_info_th && <div>
                <p className="mb-2 text-black">ช่องทางการชำระเงิน</p>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: currentHotel.payment_info_th }} />
                </div>
              </div>}
              <div>
                <h5><strong>จำนวนเงินที่ต้องชำระ : </strong><span className="ms-2 text-success">{numberFormat(payAmount)} บาท</span></h5>
              </div>
              <div className="d-flex mb-2 align-items-end mt-3">
                <div>
                  <label htmlFor={`form_file_slip`} className="form-label">แนบหลักฐานการชำระเงิน</label>
                  <input accept="image/*" className="form-control" id={`form_file_slip`} type="file" onChange={(e) => {
                    if (e?.target?.files && e?.target?.files[0]) {
                      setForm({ ...form, slip_file: e.target.files[0] })
                    }
                  }} />
                </div>
                <div className="ms-2" style={{ width: 40 }}>
                  {!!form.slip_file &&
                    <img src={URL.createObjectURL(form.slip_file)} className="rounded-sm" style={{ width: 40, height: 40 }} onClick={() => {
                      setIsLightboxOpen(true)
                    }} />}
                </div>
              </div>
              <Button disabled={submitting} className="btn btn-sm btn-warning" onClick={() => {
                ReactGA.event({
                  category: "BookingProcess",
                  action: `${currentHotel?.code}_camping_booking_confirm`,
                  label: `${currentHotel?.name_th} - Camping Booking Confirm`,
                });

                if (dayQty < 1) {
                  toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
                  return
                }

                if (!form.camp_type_id) {
                  toast.warn('กรุณาเลือกประเภทการเข้าพัก')
                  return
                }

                if (!form.contact_name) {
                  toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
                  return
                }

                if (!form.contact_tel_1) {
                  toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
                  return
                }

                if (!form.total_price || form.total_price < 1) {
                  toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                  return
                }

                if (payAmount > 0 && !form.slip_file) {
                  toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
                  return
                }

                if (!form.guest_qty && form.guest_qty < 1) {
                  toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
                  return
                }

                if (!validateAddons()) {
                  toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
                  return
                }

                setShowConfirmModal(true)
              }}>ยืนยันการจอง</Button>
            </div>
          </div>
          {isLightboxOpen && !!form.slip_file && (
            <Lightbox
              medium={URL.createObjectURL(form.slip_file)}
              large={URL.createObjectURL(form.slip_file)}
              alt={'Payment Slip'}
              onClose={() => {
                setIsLightboxOpen(false)
              }}
              hideDownload
              hideZoom
            />
          )}
        </div>}
      <Modal className="fade modal-close-out" show={showConfirmModal}>
        <Modal.Header>
          <Modal.Title>{'ยืนยันการจอง'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <p>
            <span>{'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'} </span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"secondary"} onClick={() => setShowConfirmModal(false)}>ปิด</Button>
          <Button disabled={submitting} variant="warning" onClick={() => {
            ReactGA.event({
              category: "Booking Submit Button",
              action: `${currentHotel?.code}_camping_booking_submit`,
              label: `${currentHotel?.name_th} - Camping Booking Submit`,
            });

            if (dayQty < 1) {
              toast.warn('กรุณาระบุวันเข้าพักให้ถูกต้อง')
              return
            }

            if (!form.contact_name) {
              toast.warn('กรุณาระบุชื่อผู้ติดต่อ')
              return
            }

            if (!form.contact_tel_1) {
              toast.warn('กรุณาระบุเบอร์ติดต่อ อย่างน้อย 1 หมายเลข')
              return
            }

            if ((!form.total_price || form.total_price < 1)) {
              toast.warn('ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              return
            }

            if (payAmount > 0 && !form.slip_file) {
              toast.warn('กรุณาแนบหลักฐานการชำระเงิน')
              return
            }

            if (!form.guest_qty && form.guest_qty < 1) {
              toast.warn('กรุณาระบุจำนวนผู้เข้าพัก')
              return
            }

            if (!validateAddons()) {
              toast.warn('กรุณาเลือกอุปกรณ์เสริมให้ครบถ้วน หรือลบรายการที่ไม่ต้องการออก')
              return
            }

            // if (!checkGuestQtyWithStartDateEndDate(startDate, endDate, form.guest_qty, form.guest_child_qty)) {
            //   toast.warn('จำนวนผู้เข้าพักเกินจำนวนที่สามารถจองได้')
            //   return
            // }

            setSubmitting(true)

            const rawForm = {
              ...form,
              day_qty: dayQty,
              guest_child_qty: form.guest_child_qty || 0,
              guest_baby_qty: form.guest_baby_qty || 0,
              tent_qty: form.tent_qty || 0,
              extra_person_qty: form.extra_person_qty || 0,
              extra_child_qty: form.extra_child_qty || 0,
              extra_baby_qty: form.extra_baby_qty || 0,
              is_walkin: 1,
              discount_code: couponCode,
              total_discount: discountPrice || 0,
              start_date: moment(startDate).format('YYYY-MM-DD'),
              end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
              camping_zone_type: campItemData?.campZone?.type,
              camp_zone_item_id: campItemData?.id,
              camp_zone_id: campItemData?.campZone?.id,
              hotel_id: currentHotel?.id,
            }

            const formData = new FormData();
            for (var key in rawForm) {
              if (key !== 'slip_file') {
                formData.append(key, rawForm[key]);
              }
            }

            if (payAmount > 0) {
              formData.append("file", rawForm.slip_file);
            }

            if (bookingItems && bookingItems.length) {
              bookingItems.map((bItem, i) => {
                formData.append(`booking_items[${i}]`, JSON.stringify(bItem));
              })
            }

            if (addons && addons.length) {
              addons.map((addonObj, i) => {
                formData.append(`booking_addons[${i}]`, JSON.stringify(addonObj));
              })
            }

            api.request(`bookings/bookingformweb`, (res, success) => {
              setSubmitting(false)
              setShowConfirmModal(false)
              if (!success) {
                return
              } else {
                toast.success('ทำการจองเรียบร้อยแล้ว')
                navigate(`/once/status/${res.id}/${res.booking_no}`)
              }
            }, formData, 'POST')
          }}>
            ยืนยันการจอง
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade" show={showSuggestionModal}>
        <Modal.Body className="d-flex flex-column p-0">
          <img src={"/assets/img/booking/calendar_suggestion.png"} alt="img" />
        </Modal.Body>
        <Modal.Footer className="p-1">
          <Button variant={"secondary"} onClick={() => setShowSuggestionModal(false)}>ปิด</Button>
        </Modal.Footer>
      </Modal>
      <Modal className="fade modal-close-out" show={showCouponSuggestionModal} onHide={() => setShowCouponSuggestionModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body className="d-flex flex-column p-0">
          <img src={"/assets/img/booking/oncecoupon_suggestion.jpeg"} alt="img" />
        </Modal.Body>
      </Modal>
      {isLightboxOpen && (
        <Lightbox
          medium={"/assets/img/booking/calendar_suggestion.png"}
          large={"/assets/img/booking/calendar_suggestion.png"}
          alt={'IMG'}
          onClose={() => {
            setIsLightboxOpen(false)
          }}
          hideDownload
          hideZoom
        />
      )}
      <OnceFooter />
    </>
  );
}
