import React from 'react';
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from '../components/BackToTop';
// import AnimateMouse from '../components/common/AnimateMouse';
import ContextProvider from '../components/context/ContextProvider';
// import Home from '../components/Home/Home';
// import HomeThree from '../components/HomeThree/HomeThree';
import Home from '../components/Home';
import Rooms from '../components/Room/Rooms';
import BookingStatus from '../components/Booking/BookingStatus';
import BookingAvailable from '../components/Booking/BookingAvailable';
import BookingGenerateUrl from '../components/Booking/BookingGenerateUrl';
import About from '../components/Pages/About/About';
import Blog from '../components/Pages/Blog/Blog';
import BlogDetails from '../components/Pages/BlogDetails/BlogDetails';
import ContactUs from '../components/Pages/ContactUs/ContactUs';
import FAQ from '../components/Pages/FAQ/FAQ';
import Portfolio from '../components/Pages/Portfolio/Portfolio';
import PortfolioDetails from '../components/Pages/PortfolioDetails/PortfolioDetails';
import Price from '../components/Pages/Price/Price';
import Service from '../components/Pages/Service/Service';
import ServiceDetails from '../components/Pages/ServiceDetails/ServiceDetails';
import TeamDetails from '../components/Pages/TeamDetails/TeamDetails';
import TeamPage from '../components/Pages/TeamPage/TeamPage';
import BookingFromCustomer from '../components/Booking/BookingFromCustomer';
import CheckCalendarWith3D from '../components/partial/CheckCalendarWith3D';
import CampingBooking from '../components/Booking/CampingBooking';
import CampingZoneOverview from '../components/Booking/CampingZoneOverview';
import CampingZoneBooking from '../components/Booking/CampingZoneBooking';
import BookingOverview from '../components/Booking/BookingOverview';
import BookingCleanerOverview from '../components/Booking/BookingCleanerOverview';
import VoucherOverview from '../components/Coupon/VoucherOverview';

const AppNavigation = () => {
  return (
    <ContextProvider>
      {/* <AnimateMouse/> */}
      <Routes>
        {/* <Route path="/" element={<HomeTwo />} /> */}
        <Route path="/:code" element={<Rooms />} />
        <Route path="/once/:id" element={<Home />} />
        <Route path="/once/close-all-booking/:hotel_id" element={<Home />} />
        <Route path="/once/admin-booking-generate-url" element={<BookingGenerateUrl />} />
        <Route path="/once/camping-booking/:hotel_id" element={<CampingBooking />} />
        <Route path="/once/camping-zone-overview/:hotel_id" element={<CampingZoneOverview />} />
        <Route path="/once/:hotel_id/booking-overview" element={<BookingOverview />} />
        <Route path="/once/:camp_item_id/camping-booking" element={<CampingZoneBooking />} />
        <Route path="/once/:hotel_id/cleaner-overview" element={<BookingCleanerOverview />} />
        <Route path="/once/self-booking/:book_type/:room_id/:t_price/:s_date/:e_date" element={<BookingFromCustomer />} />
        {/* <Route path="/once/check-status" element={<BookingStatus />} /> */}
        <Route path="/once/calendar/:id" element={<CheckCalendarWith3D />} />
        <Route path="/once/status/:id/:code" element={<BookingStatus />} />
        <Route path="/once/vc/:code/overview" element={<VoucherOverview />} />
        {/* <Route path="/once/check-available/:s_date/:e_date" element={<BookingAvailable />} /> */}
        {/* <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/team" element={<TeamPage/>} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/service" element={<Service />} />
        <Route path="/service-details" element={<ServiceDetails />} /> */}
        {/* <Route path="/price" element={<Price />} />
        <Route path="/contact" element={<ContactUs />} /> */}
        {/* <Route path="/faq" element={<FAQ />} /> */}
        {/* <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} /> */}
        {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
        <Route path="*" element={<Rooms />} />
      </Routes>
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;