import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./../common/Footer/OnceFooter";
import { useParams, useLocation } from 'react-router-dom';
import api from './../../utils/api';
import { AppContext } from "./../context/ContextProvider";
import { Modal, Button, Row, Col, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import { toast } from 'react-toastify';
import moment from "moment";
import 'moment/locale/th'
import _ from "lodash";
import Lightbox from 'react-image-lightbox';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactGA from 'react-ga4';
import Menu from "../common/Menu/Menu";
import { Link } from "react-router-dom";
import CampingZoneAModal from './CampingZoneAModal';
import CampingZoneBModal from './CampingZoneBModal';
import CampingZoneDModal from './CampingZoneDModal';
import CampingZoneEModal from './CampingZoneEModal';

export default function CampingZoneOverview() {
  const navigate = useNavigate();
  const { hotel_id } = useParams();
  const location = useLocation();
  const { handleSetCurrentHotel, currentHotel } = useContext(AppContext);
  const [zones, setZones] = useState([]);
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)
  const [itemForModal, setItemForModal] = useState(null)

  console.log('currentHotelss', currentHotel)

  const fetchHotel = () => {
    api.request(`hotels/${hotel_id}`, (data) => {
      if (!!data && !!data.allow_walkin) {
        handleSetCurrentHotel(data)
        fetchCampingZone()

        // ReactGA.send({ hitType: "pageview", page: `camping-booking/${hotel_id}`, title: `${data?.name_th} Camping Booking Form` });

        // ReactGA.event({
        //   category: "BookingProcess",
        //   action: `${data?.code}_camping_booking_form`,
        //   label: `Camping Booking Form - ${data?.name_th}`,
        // });
      }
    }, {})
  }

  const fetchCampingZone = () => {
    api.request(`camp-zones/all`, (data) => {
      setZones(data || [])
    }, {
      hotel_id: hotel_id,
      enabled: true
    })
  }

  useEffect(() => {
    fetchHotel()
  }, [hotel_id]);

  const checkZonePriceType = (zoneType) => {
    if (zoneType === 'camp-a' || zoneType === 'camp-b' || zoneType === 'camp-d') {
      return 'area'
    } else if (zoneType === 'camp-c') {
      return 'person'
    } else if (zoneType === 'camp-e') {
      return 'area_with_person'
    }
  }

  return (
    <>
      <Menu header_style_2={true} hide_menu={true} />
      {(!currentHotel || !currentHotel?.allow_walkin) ?
        <div className="text-center py-5 px-2 px-md-5">
          <h4>ขออภัย การจองพื้นที่ Camping ไม่สามารถใช้งานได้ในขณะนี้</h4>
        </div>
        :
        <div className="py-5 px-2 px-md-5">
          <div className="text-center">
            <h2><i className="fal fa-campground"></i> เลือกโซน CAMPING</h2>
          </div>
          <div className="project-page-list pt-50 pb-50">
            <div className="container">
              <div className="row">
                {zones.map((item) => {
                  const img = item?.zone_medias?.length ? item?.zone_medias[0].file_path : ''
                  const roomImgForSlide = _.map(item?.zone_medias || [], 'file_path')
                  const isZonePrice = checkZonePriceType(item.type) === 'area'

                  return (
                    <div key={item.id} className="col-lg-4 col-md-6 col-12">
                      <div className="pj-list-item mb-50">
                        <div className="image-thumb-with-preview mb-10 rounded">
                          <img src={img} alt={item.name_th} />
                          <span onClick={() => {
                            setImgForPreview(roomImgForSlide)
                          }} className="image-preview-span"><i className="fal fa-images"></i></span>
                          <span className="price-span rounded">฿{numberFormat(item.base_price, true)}/{isZonePrice ? 'ล็อก' : 'คน'}</span>
                        </div>
                        <h4 className="pj-list__title d-none d-md-block custom-room-title">
                          <div className="py-2" style={{ cursor: 'pointer' }} onClick={() => {
                            setItemForModal(item)
                            // ReactGA.event({
                            //   category: "Camping Zone Button",
                            //   action: `${hotel?.code}_${item.name}_booking_click`,
                            //   label: `${hotel?.name_th} - Booking Button with ${item.name_th}`,
                            // });
                          }}>{item.name_th}
                            <span className="ms-2 text-success border border-success rounded p-2"><i className="fal fa-calendar" style={{ fontSize: 18 }}></i> BOOKING</span>
                          </div>
                        </h4>
                        <div>
                         {item.type != 'camp-e' && <span className="mb-0"><i className="fal fa-user"></i>  {item.person_limit} Persons</span>}
                          {item.type != 'camp-c' && <span className="mx-2 mb-0"><i className="fal fa-campground"></i></span>}
                          {!item.base_size_custom && item.type != 'camp-c' && <span>{item.base_size_width} x {item.base_size_height}</span>}
                          {!!item.base_size_custom && <span>{item.base_size_custom}</span>}
                        </div>
                        {item?.facilities?.length > 0 && <div className="py-2">
                          {item?.facilities?.map((h, i) => {
                            return (
                              <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-2">
                                <i className="fal fa-check text-success me-2"></i>
                                <span className="mb-0">{h.name_th}</span>
                              </div>
                            )
                          })}
                        </div>}
                        <span>
                          <a href="#">{item.description_th}</a>
                        </span>
                        <h4 className="pj-list__title  d-block d-md-none">
                          <div className="py-2" style={{ cursor: 'pointer' }} onClick={() => {
                            setItemForModal(item)
                            // ReactGA.event({
                            //   category: "Camping Zone Button",
                            //   action: `${hotel?.code}_${item.name}_booking_click`,
                            //   label: `${hotel?.name_th} - Booking Button with ${item.name_th}`,
                            // });
                          }}>{item.name_th}
                            <span className="ms-2 text-success border border-success rounded p-2"><i className="fal fa-calendar" style={{ fontSize: 18 }}></i> BOOKING</span>
                          </div>
                        </h4>
                      </div>
                    </div>
                  )
                })}

              </div>
            </div>
          </div>
        </div>}
      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
      <CampingZoneAModal
        show={!!itemForModal && itemForModal?.type === 'camp-a'}
        onHide={() => setItemForModal(null)}
        zoneSelected={itemForModal}
      />
      <CampingZoneBModal
        show={!!itemForModal && itemForModal?.type === 'camp-b'}
        onHide={() => setItemForModal(null)}
        zoneSelected={itemForModal}
      />
      <CampingZoneDModal
        show={!!itemForModal && itemForModal?.type === 'camp-d'}
        onHide={() => setItemForModal(null)}
        zoneSelected={itemForModal}
      />
      <CampingZoneEModal
        show={!!itemForModal && itemForModal?.type === 'camp-e'}
        onHide={() => setItemForModal(null)}
        zoneSelected={itemForModal}
      />
      <OnceFooter />
    </>
  );
}
