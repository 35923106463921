import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import RoomListModal from './../Room/RoomListModal';
import RoomDetailModal from './../Room/RoomDetailModal';
import BookingModal from './../Booking/BookingModal';
import Slider from "react-slick";
import slider2 from "../../data/SliderHomePageTwo.json";
import _ from "lodash";
import { AppContext } from "../context/ContextProvider";
import { numberFormat } from './../../utils/functions';
import VideoViewerModal from "./../Room/components/VideoViewerModal";
import BookingCalendar from "../Booking/BookingCalendar";

const TopCtaAreaForCloseAll = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date())
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)
  const [dateForBooking, setDateForBooking] = useState(null)
  const { currentHotel } = useContext(AppContext);
  const [bookings, setBookings] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [room, setRoom] = useState(roomSelected)

  const roomImageSlidersettings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    fade: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const roomImgForSlide = _.map(currentHotel?.hotel_medias || [], 'file_path')

  return (
    <>
      {/* <!-- cta area start --> */}
      <div id="room_booking" className="tp-cta-area tp-cta-area-page-two pl-110 pr-200">
        <div className="container-fluid">
          <div className="tp-cta-wrapper home-section-1 pb-105">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle tp-subtitle-before mb-25">
                    {currentHotel?.name_th}
                  </span>
                  <h2 className="tp-section-title tp-section-title-large">
                    {currentHotel?.close_all_booking_title}
                  </h2>
                  <span>{currentHotel?.district?.name_th} {currentHotel?.province?.name_th}</span>
                </div>
              </div>
            </div>
            <div className={`row align-items-center`}>
              <div className="col-lg-6">
                <BookingCalendar room_id={null} hotel_id={currentHotel?.id} is_close_all={true} />
              </div>
              {!!currentHotel?.hotel_medias?.length && <div className="col-lg-6 text-end">
                <div className="about-page-img mt-4" onClick={() => setImgForPreview(roomImgForSlide)} >
                  <Slider  {...roomImageSlidersettings}>
                    {currentHotel?.hotel_medias.map((img, i) => {
                      return (
                        <div key={`s-room-img-${i + 1}`} className="w-img rounded">
                          <img className="rounded" src={img.file_path} alt="img" />
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>}
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-8">
                  {!!currentHotel?.highlights && !!currentHotel?.highlights.length && <div className="rounded border p-3" >
                    <p className="text-black">Highlights/จุดเด่นที่พัก</p>
                    <div className="">
                      {currentHotel?.highlights.map((h, i) => {
                        return (
                          <div key={`s-room-highlight-${i + 1}`} className="d-inline-block ms-3">
                            <i className="fa fa-star text-warning me-2"></i>
                            <span>{h.name_th}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                  {!!currentHotel?.facilities && !!currentHotel?.facilities.length && <div className="p-3" >
                    <p className="text-black">Facilities/สิ่งอำนวยความสะดวก</p>
                    <div className="">
                      {currentHotel?.facilities.map((h, i) => {
                        return (
                          <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-3">
                            <i className="fal fa-check text-success me-2"></i>
                            <span>{h.name_th}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                </div>
                <div className="col-lg-4">
                  {!!currentHotel?.hotel_nearby_places && !!currentHotel?.hotel_nearby_places.length && <div className="p-3" >
                    <p className="text-black">Nearby Places/สถานที่ใกล้เคียง</p>
                    <div className="">
                      {currentHotel?.hotel_nearby_places.map((h, i) => {
                        const distance = h.distance ? (Number(h.distance) / 1000) : null
                        return (
                          <div key={`s-room-near-${i + 1}`} className="">
                            <i className="fas fa-map-marker-alt text-black me-2"></i>
                            <span>{h.name_th}</span>
                            {!!distance && <small className="ms-2">{'(' + numberFormat(distance, true, '0', 1) + ' km.)'}</small>}
                          </div>
                        )
                      })}
                    </div>
                  </div>}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
      <BookingModal
        show={!!dateForBooking}
        onHide={(_ignore_reload) => {
          setDateForBooking(null)
          if (!_ignore_reload) {
            window.location.reload()
          }
        }}
        date={dateForBooking}
        bookingList={bookings}
        is_close_all={true}
      />
      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
    </>
  );
};

export default TopCtaAreaForCloseAll;
