import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import api from './../../utils/api';
import moment from "moment";
import 'moment/locale/th'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PolicyConfirmModal from './PolicyConfirmModal';
import RoomListModal from './../Room/RoomListModal';
import { AppContext } from "../context/ContextProvider";
import { numberFormat, inputKeyPressOnlyNumber, optionValue } from './../../utils/functions';
import _ from "lodash";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactGA from 'react-ga4';

moment.locale('th')

const CampingZoneAModal = ({ show, onHide = () => { }, zoneSelected = null }) => {
  const navigate = useNavigate();
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)
  const [items, setItems] = useState([])

  const roomImgForSlide = _.map(zoneSelected?.zone_medias || [], 'file_path')
  const coverImage = roomImgForSlide.length > 0 ? roomImgForSlide[0] : null

  const fetchItems = (zone_id) => {
    api.request(`camp-zones/items/find`, (data) => {
      setItems(data || [])
    }, { camp_zone_id: zone_id, enabled: true })
  }

  useEffect(() => {
    if (!show || !zoneSelected?.id) {
      setItems([])
      return
    }

    fetchItems(zoneSelected?.id)
  }, [show, zoneSelected]);

  return (
    <>
      <Modal size="lg" show={show} onHide={() => onHide(true)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title> {zoneSelected?.name_th} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!zoneSelected && <div>
            <Row>
              <Col xs={12} md={6}>
                {!!coverImage && <div>
                  <img className="rounded" src={coverImage} alt={zoneSelected?.name_th} style={{ width: '100%' }} />
                  <Button variant="outline-success" onClick={() => setImgForPreview(roomImgForSlide)} className="mt-2"><i className="fal fa-images me-2"></i>
                    ดูภาพเพิ่มเติม</Button>
                </div>}
                {zoneSelected?.facilities?.length > 0 && <div className="py-2">
                  {zoneSelected?.facilities?.map((h, i) => {
                    return (
                      <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-2">
                        <i className="fal fa-check text-success me-2"></i>
                        <span className="mb-0">{h.name_th}</span>
                      </div>
                    )
                  })}
                </div>}
              </Col>
              <Col xs={12} md={6} className="mt-2 mt-md-0">
                <p>{zoneSelected?.description_th}</p>
                <div className="text-black">
                  {!zoneSelected?.base_size_custom && <span>ขนาดพื้นที่ {zoneSelected.base_size_width} x {zoneSelected.base_size_height}</span>}
                  {!!zoneSelected?.base_size_custom && <span>ขนาดพื้นที่ {zoneSelected.base_size_custom}</span>}
                </div>
                <div className="text-black">รองรับผู้เข้าพัก สูงสุด {numberFormat(zoneSelected?.person_limit, true)} ท่าน</div>
                <div className="border border-success rounded p-2 mt-2">
                  <h5>ราคา</h5>
                  <div>วันธรรมดา {numberFormat(zoneSelected?.base_price, true)} บาท / ล็อก</div>
                  <div>วันหยุดนักขัตฤกษ์ {numberFormat((zoneSelected?.base_holiday_price || zoneSelected?.base_price), true)} บาท / ล็อก</div>

                  {!!zoneSelected?.extra_person_limit && zoneSelected?.extra_person_limit > 0 && <div className="mt-2">
                    <div className="bg-danger mb-2" style={{ height: 1, width: '100%' }}></div>
                    <div className="text-danger">* เสริมผู้เข้าพักได้สูงสุด {numberFormat(zoneSelected?.extra_person_limit, true)} ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันธรรมดา ผู้ใหญ่ {numberFormat(zoneSelected?.extra_person_price, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันธรรมดา เด็กโต {numberFormat(zoneSelected?.extra_person_child_price, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันหยุดนักขัตฤกษ์ ผู้ใหญ่ {numberFormat(zoneSelected?.extra_person_holiday_price || zoneSelected?.extra_person_price, true)} บาท / ท่าน</div>
                    <div className="ms-3">ราคาเสริมวันหยุดนักขัตฤกษ์ เด็กโต {numberFormat(zoneSelected?.extra_person_child_holiday_price || zoneSelected?.extra_person_child_price, true)} บาท / ท่าน</div>
                  </div>}
                </div>
              </Col>
            </Row>
            <div className="mt-3 rounded" style={{ backgroundColor: '#FFCB96', color: '#000000', padding: '5px 10px' }}>
              <i className="fas fa-calendar-check px-2" style={{ fontSize: 18 }}></i>
              เลือกล็อก เพื่อทำการจอง
            </div>
            <Row className="mt-3">
              {items && items.map((item, index) => {
                return (
                  <Col xs={6} sm={4} lg={3} key={`box-${index}`}>
                    <div className={`border border-${item.enabled ? 'success' : 'danger'} rounded text-center mb-2 py-2`} style={{ position: 'relative' }}>
                      <h5>[{item.code}] : {item.name_th}</h5>
                      {!item?.size_custom && <span>{item?.size_width || item.campZone?.base_size_width} x {item.size_height || item.campZone?.base_size_height}</span>}
                      {!!item?.size_custom && <span>{item?.size_custom}</span>}
                      <div>
                        <span className="text-black"><i className="fal fa-tag me-1"></i>฿{numberFormat((item?.price || item?.campZone?.base_price), true)}</span>
                        <span className="text-black ms-2"><i className="fal fa-user me-1"></i>{numberFormat((item?.person_limit || item?.campZone?.person_limit), true)}</span>
                      </div>
                      <div>
                        <Button variant="outline-success" size="sm" className="mt-1" onClick={() => {
                          navigate(`/once/${item.id}/camping-booking`)
                        }}>BOOKING</Button>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>}
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
    </>
  );
};

export default CampingZoneAModal;
