import React, { useState, useRef, useEffect, useContext } from "react";
import api, { WEB_BASE_URL } from './../../utils/api';
import moment from "moment";
import { Modal, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from "lodash";
import { AppContext } from "../context/ContextProvider";
import { numberFormat } from './../../utils/functions';
import BookingModal from "./BookingModal";
import ReactGA from 'react-ga4';

const BookingPriceSummary = ({ showWith360 = false, forceShowBookingModal = false, is_close_all = false }) => {
  const { bookStartDate, bookEndDate, bookingData, roomSelected, currentHotel } = useContext(AppContext);
  const [showBookingModal, setShowBookingModal] = useState(false)

  const isBooked = !!bookStartDate && !!bookEndDate && !!bookingData?.length
  const a = moment(new Date(moment(bookEndDate).format('YYYY-MM-DD')));
  const b = moment(new Date(moment(bookStartDate).format('YYYY-MM-DD')));
  const dayQty = a.diff(b, 'days') || 0

  const totalPrice = _.sumBy(bookingData, (o) => Number(o.price || 0))
  const bookDataJson = !!bookingData?.length ? JSON.stringify(bookingData) : null
  const bookUrl = `${WEB_BASE_URL}/once/${roomSelected?.id}?s_date=${moment(bookStartDate).format('YYYY-MM-DD')}&e_date=${moment(bookEndDate).format('YYYY-MM-DD')}&book_data=${bookDataJson}`

  useEffect(() => {
    if (forceShowBookingModal) {
      setShowBookingModal(true)
    }
  }, [forceShowBookingModal])

  return (
    <>
      <div className={`${showWith360 ? 'p-1' : 'p-3'} text-end ${showBookingModal ? 'd-none' : 'd-block'}`} style={{ width: '100%', backgroundColor: '#FFFFFF', borderTop: '1px solid #000000', zIndex: 99999, bottom: 0, position: 'sticky', top: 0 }}>
        {!!isBooked && <div className={`d-flex  ${showWith360 ? 'px-3' : ''}`}>
          <div className="text-start" style={{ flex: 1 }}>
            <div><small>{moment(bookStartDate).format('DD/MM/YYYY')} - {moment(bookEndDate).format('DD/MM/YYYY')} ({dayQty} คืน)</small></div>
            <div style={{ color: '#000000', fontSize: 20 }}>฿{numberFormat(totalPrice, true)}</div>
          </div>
          {!!showWith360 && <a className={`py-2 px-5 btn-sm rounded`} style={{ backgroundColor: '#000000', border: 0, borderRadius: 0, color: '#FFFFFF' }}
            href={bookUrl} target="_blank" onClick={() => {
              ReactGA.event({
                category: "BookingProcess",
                action: `${currentHotel?.code}_${roomSelected?.room_no}_360_booking_click`,
                label: `Check Calendar From 360 - ${currentHotel?.name_th} : ${roomSelected?.name_th}`,
              });
            }}>
            จอง
          </a>}
          {!showWith360 && <Button onClick={() => {
            ReactGA.event({
              category: "BookingProcess",
              action: `${currentHotel?.code}_${roomSelected?.room_no || 'closeall'}_ft_booking_click`,
              label: `Click for Open Booking Modal - ${currentHotel?.name_th} : ${roomSelected?.name_th || 'closeall'}`,
            });

            setShowBookingModal(true)
          }} className={`py-3 px-5 rounded`} style={{ backgroundColor: '#000000', border: 0, borderRadius: 0 }}>BOOK NOW</Button>}
        </div>}

        {(currentHotel?.id && (!currentHotel?.hide_booking_process) && !isBooked) && <div className={`d-flex align-items-center ${showWith360 ? 'px-3' : ''}`} style={{ height: showWith360 ? 52 : 56 }}>
          <div className="text-start text-danger" style={{ flex: 1 }}>
            กรุณาเลือกวันที่ต้องการเข้าพักบนปฏิทิน
          </div>
          <Button className={`rounded ${showWith360 ? 'py-1' : 'py-3'} px-5 ${showWith360 ? 'btn-sm' : ''}`} disabled style={{ backgroundColor: 'gray', border: 0, height: showWith360 ? 52 : 'auto' }}>BOOK NOW</Button>
        </div>}
        {(currentHotel?.id && !!currentHotel?.hide_booking_process && !isBooked) && <div className={`d-flex align-items-center`} style={{ height: 56 }}>
          <div className="text-start text-danger" style={{ flex: 1 }}>
            {!!currentHotel?.external_text_for_copy && <div style={{ cursor: 'pointer' }} onClick={() => {
              navigator.clipboard.writeText(currentHotel?.external_text_for_copy)
            }}> {currentHotel?.external_text_for_copy} <span className="text-muted">(กดเพื่อคัดลอกข้อความ)</span></div>}
          </div>
          <a className={`rounded py-3 px-5`} style={{ backgroundColor: '#000000', border: 0, borderRadius: 0, color: '#FFFFFF' }} href={currentHotel?.external_booking_link} target="_blank" onClick={() => {
            ReactGA.event({
              category: "BookingProcess",
              action: `${currentHotel?.code}_${roomSelected?.room_no || 'closeall'}_ft_booking_click`,
              label: `Open External Link - ${currentHotel?.name_th} : ${roomSelected?.name_th || 'closeall'}`,
            });
          }}>BOOK NOW</a>
        </div>}
      </div>
      <BookingModal
        show={showBookingModal}
        onHide={(_ignore_reload) => {
          setShowBookingModal(false)
          if (!_ignore_reload) {
            window.location.reload()
          }
        }}
        is_close_all={is_close_all}
      />
    </>
  );
};

export default BookingPriceSummary;
